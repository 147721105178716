/* oswald-regular - latin */
@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 400
    src: local('')
    src: url('./fonts/oswald-v49-latin-regular.woff2') format('woff2')
    src: url('./fonts/oswald-v49-latin-regular.woff') format('woff')
/* oswald-700 - latin */
@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 700
    src: local('')
    src: url('./fonts/oswald-v49-latin-700.woff2') format('woff2')
    src: url('./fonts/oswald-v49-latin-700.woff') format('woff')

// Change bootstrap variables
$body-bg: black
$body-color: white
@import "../../node_modules/bootstrap/scss/bootstrap"
// Change Font to Oswald
body
    font-family: 'Oswald', sans-serif
    overflow: hidden

a
    // Remove blue color on links
    color: white
.bold
    font-weight: 700
.three
    width: 100%
    height: 25vh
    margin: 30vh 0 70vh 0

// @media screen and (max-width: 600px)
//     .three
//         height: 10vh

#container-top
    position: fixed
    top: 0%
    width: 100%
    height: 60px
    background-image: url("./stzpkt.jpg")
    background-size: 200px
    background-position: center
    background-repeat: repeat-x
    z-index: 2

#container-bot
    position: fixed
    top: calc(100% - 60px)
    width: 100%
    height: 60px
    background-image: url("./stzpkt.jpg")
    background-size: 200px
    background-position: center
    background-repeat: repeat-x
    z-index: 2

.btn
    background: none
    color: white
    border: none
    &:hover
        background: none
        color: white
        border: none

button:focus
    outline: 0

.nav-btn
    letter-spacing: 0.1em
    font-weight: bold
    padding: .1em 0.3em
    text-decoration: none

.nav-list
    color: white
    border-top-style: solid
    border-bottom-style: solid
    border-width: 2px

    &:before
        float: left
        content: "-"
        font-weight: bold
    &:first-child:before
        content: ""

.hover-bold:hover
    font-weight: bold
.hover-bold
    text-transform: uppercase
    letter-spacing: 0.2em
    padding: .1em 0.3em
    text-decoration: none
    white-space: nowrap

.artist-info
    color: white
    margin-bottom: 1em
    border-left-style: solid
    border-width: 4px
    img
        -webkit-filter: grayscale(100%)
        filter: grayscale(100%)

.artist-name
    text-transform: uppercase
    font-weight: bold

.soundcloud
    color: white
    margin: 0 1em 0 0
    font-weight: bold
    padding: 0
    text-transform: none
    letter-spacing: 1px
    text-decoration: none

.music-banner
    text-transform: uppercase
    font-weight: bold

.music-item
    margin: 1em
.music-list-item
    max-width: 350px

.dashed
    position: relative
.dashed::before
    content: ""
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPGc+CiAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogIDxsaW5lIHN0cm9rZS1kYXNoYXJyYXk9IjUsNSIgc3Ryb2tlLWxpbmVjYXA9InVuZGVmaW5lZCIgc3Ryb2tlLWxpbmVqb2luPSJ1bmRlZmluZWQiIGlkPSJzdmdfMSIgeTI9IjEwIiB4Mj0iMTAiIHkxPSIwIiB4MT0iMCIgb3BhY2l0eT0iTmFOIiBzdHJva2U9IiNmZmZmZmYiIGZpbGw9Im5vbmUiLz4KIDwvZz4KPC9zdmc+")
    background-repeat: repeat
    position: absolute

    top: 0px
    right: 0px
    bottom: 0px
    left: 0px
    opacity: 0.5
    z-index: -1

.event-grid
    margin: 0.5em
    padding: 0.5em
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    align-items: center
    justify-content: center

.event-grid-item
    padding: 0.5em

// medium screens
@media screen and (min-width: 101px)
    .event-grid-item
        flex: 0 1 auto

// small screens
@media screen and (max-width: 100px)
    .event-grid-item
        flex: 0 1 90%
.event-grid-item-artist
    flex: 0 1 auto
    padding: 0.5em

.event-image
    display: block
    width: 100%
    height: auto
    transition: all 0.5s ease-in-out

@media screen and (min-width: 501px)
    .event-image
        max-width: 500px

.event-container
    position: relative

.event-info
    display: flex
    visibility: hidden
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    flex-direction: column
    align-content: flex-start
    padding: 1em
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, padding 0.5s ease-in-out

@media screen and (max-width: 480px)
    .event-info
        position: static

#scr-div
    position: absolute
    opacity: 0.5
    top: 75%
    left: calc(50% - 10px)
    span
        display: block
        width: 5px
        height: 5px
        transform: rotate(45deg)
        border-right: 2px solid white
        border-bottom: 2px solid white
        margin: 4px 0 3px 5px

.scroll
    position: absolute
    left: 50%
    margin-top: -50px
    right: 50%
    width: 50px
    height: 100px

.mouse
    height: 21px
    width: 14px
    border-radius: 10px
    transform: none
    border: 2px solid white
    top: 170px

.wheel
    height: 5px
    width: 2px
    display: block
    margin: 5px auto
    background: white
    position: relative

    &
        animation: mouse-wheel 1.2s ease infinite

@keyframes mouse-wheel
    0%
        opacity: 1
        transform: translateY(0)

    100%
        opacity: 0
        transform: translateY(6px)

@keyframes mouse-scroll
    0%
        opacity: 0

    50%
        opacity: .5

    100%
        opacity: 1

/* width */
::-webkit-scrollbar
    width: 10px

/* Track */
::-webkit-scrollbar-track
    background: #141414

/* Handle */
::-webkit-scrollbar-thumb
    background: #313131
    border-radius: 5px

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
    background: #3a3a3a
